import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private isLoadingSub: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

  public getLoadingSub(): Observable<boolean> {
    return this.isLoadingSub.asObservable();
  }

  public setLoading(status: boolean): void {
    this.isLoadingSub.next(status);
  }
}
