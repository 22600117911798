import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  private _routerSub: Subscription;
  private routerSubject: Subject<any> = new Subject();
  private currentRoute: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this._routerSub = this.router.events.subscribe((event)=>{
      if(event instanceof NavigationEnd){
        this.currentRoute = event.url;
        this.routerSubject.next(event);
      }
    })
  }

  public navigate(page: string, params: any = {}){
    this.router.navigate([page],{
      queryParams: params
    })
  }

  public getCurrentRoute(): string {
    return this.currentRoute;
  }

  public subToRoute(): Observable<any> {
    return this.routerSubject.asObservable();
  }
}