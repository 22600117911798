import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarHeaderService {

  private isSidebarVisible: boolean = false;
  private isHeaderVisible: boolean = false;
  
  private sidebarSub: Subject<boolean> = new Subject();
  private headerSub: Subject<boolean> = new Subject();

  constructor() { }

  public setHeader(status: boolean): void {
    this.headerSub.next(status);
    this.isHeaderVisible = status;
  }
  public setSidebar(status: boolean): void {
    this.sidebarSub.next(status);
    this.isSidebarVisible = status;
  }

  public getHeader(): boolean {
    return this.isHeaderVisible;
  }

  public getSidebar(): boolean {
    return this.isSidebarVisible;
  }

  public subToSidebar(): Observable<boolean> {
    return this.sidebarSub.asObservable()
  }

  public subToHeader(): Observable<boolean> {
    return this.headerSub.asObservable()
  }
}
