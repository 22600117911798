import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import jwt_decode from "jwt-decode";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthInfoService {

  private apiToken: any = {
    expires: null,
    token: null
  };
  private platformID: string = null;
  private tokenInfo: any;
  private userInfo: any;
  private userRole: string;
  private userOrg: string;
  private accountInfo: any;
  private isAuth: boolean = false;
  private isAuthEmitter: BehaviorSubject<boolean> = new BehaviorSubject(this.isAuth);

  constructor(
    private authService: AuthService
  ) { }

  public setApiKey(key: string){
    console.log('Set Key: '+key)
    this.platformID = key;
  }

  public isUserAuth(): boolean {
    return this.isAuth;
  }
  public isApiAuth(): boolean {
    if(this.apiToken && this.apiToken.token){
      if(this.apiToken.expires && this.apiToken.expires > Math.floor(new Date().getTime() / 1000)){
        return true;
      }
    }
    return false;
  }

  public getPlatformID(): string {
    return this.platformID;
  }

  public getUserAuthInit(): Promise<boolean> {
    return new Promise((resolve)=>this.authService.isAuthenticated$.subscribe(async (status: boolean)=>{
      this.isAuth = status;
      if(status){
        await this._saveUserInfo();
        await this._saveTokenInfo();
      }
      this.updateAuthChanges(status);
      resolve(status)
    }))
  }

  public setAccountInfo(account: any): void {
    console.log(account)
    this.accountInfo = account;
  }

  public getUserID(): string {
    if(this.apiToken.token){
      const decoded: any = jwt_decode(this.apiToken.token);
      return decoded.userID;
    }else{
      return null;
    }
  }

  public getUserInfo(): any {
    return this.userInfo;
  }

  public getUserRole(): string {
    return this.userRole;
  }

  public setUserRole(role: string): void {
    this.userRole = role;
  }

  public setUserOrg(org: string): void {
    this.userOrg = org;
  }

  public getUserOrg(): string {
    return this.userOrg;
  }

  public getAccountInfo(): any {
    return this.accountInfo;
  }

  public subToAuthChanges(): Observable<boolean> {
    return this.isAuthEmitter.asObservable();
  }
  
  private updateAuthChanges(status: boolean): void {
    this.isAuth = status;
    if(!status){
      this.apiToken.token = null;
      this.apiToken.expires = null;
      this.userOrg = null;
      this.userRole = null;
      this.tokenInfo = null;
      this.userInfo = null;
      this.accountInfo = null;
    }
    this.isAuthEmitter.next(this.isAuth);
  }
  
  private _saveUserInfo(): Promise<any> {
    return new Promise((resolve)=>this.authService.user$.subscribe(async (user: any)=>{
      this.userInfo = user;
      resolve(user);
    }))
  }
  private _saveTokenInfo(): Promise<any> {
    return new Promise((resolve)=>this.authService.idTokenClaims$.subscribe(async (token: any)=>{
      this.tokenInfo = token;
      resolve(token);
    }))
  }

  public logout(): void {
    this.updateAuthChanges(false);
    this.authService.logout({ returnTo: document.location.origin })
  }

  public getAuth0Token(): string {
    return this.tokenInfo.__raw;
  }

  public setApiToken(token: string, expires: number): void {
    this.apiToken.expires = expires;
    this.apiToken.token = token;
  }

  public getApiToken(): string {
    return (this.apiToken && this.apiToken.token) ? this.apiToken.token : this.getAuth0Token();
  }

  public hasPermission(role: string): boolean {
    switch(role){
      case "PLATFORM_ADMIN":
        return (this.getUserRole() == "PLATFORM_ADMIN") ? true : false;
      break;
      case "PLATFORM_MANAGER":
        return (this.getUserRole() == "PLATFORM_ADMIN" || this.getUserRole() == "PLATFORM_MANAGER") ? true : false;
      break;
    }
  }

  public parseRole(role: string): string {
    const roles: any = {
        "PLATFORM_ADMIN": "Platform Admin",
        "PLATFORM_MANAGER": "Platform Manager",
        "ORG_ADMIN": "Organization Admin",
        "ORG_MANAGER": "Organization Manager",
        "USER": "User"
    };
    return (roles[role]) ? roles[role] : "--";
}
}
