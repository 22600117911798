import { Injectable, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventloopService implements OnInit {

  public eventTime: any;
  public eventSub: Subject<number> = new Subject();

  constructor() { }

  ngOnInit() {}

  public startEventLoop(): void {
    clearInterval(this.eventTime)
    this.eventTime = setInterval(()=>this.eventSub.next(Math.floor(new Date().getTime() / 1000)),1000)
  }

  public subToEventLoop(): Observable<number> {
    return this.eventSub.asObservable();
  }
}
