import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  private formatPath(path: string): string {
    return (path.startsWith("/")) ? env.apiUrl+path : env.apiUrl+"/"+path;
  }
  public getApi(path: string): Promise<any> {
    return new Promise((resolve)=>{
      this.http.get(this.formatPath(path))
      .subscribe(
        (res)=>resolve({error: null, data: res}),
        (err: any)=>{
          console.log(err)
          resolve({error: (err && err.error && err.error.message) ? err.error.message : null})
        }
      )
    })
  }
  public postApi(path: string, data: any = {}): Promise<any> {
    return new Promise((resolve)=>{
      this.http.post(this.formatPath(path), data)
      .subscribe(
        (res)=>resolve({error: null, data: res}),
        (err: any)=>{
          console.log(err)
          resolve({error: (err && err.error && err.error.message) ? err.error.message : null})
        }
      )
    })
  }
  public deleteApi(path: string): Promise<any> {
    return new Promise((resolve)=>{
      this.http.delete(this.formatPath(path))
      .subscribe(
        (res)=>resolve({error: null, data: res}),
        (err: any)=>resolve({error: (err.error.message) ? err.error.message : null})
      )
    })
  }
  public putApi(path: string, params: any = {}): Promise<any> {
    return new Promise((resolve)=>{
      this.http.put(this.formatPath(path), params)
      .subscribe(
        (res)=>resolve({error: null, data: res}),
        (err: any)=>resolve({error: (err.error.message) ? err.error.message : null})
      )
    })
  }
  public getRequest(url: string): Promise<any> {
    return new Promise((resolve)=>{
      axios({
        method: 'get',
        url: url,
      })
      .then((response: AxiosResponse) => resolve({error: null, data: response.data}))
      .catch((reason: any)=>resolve({error: reason.data.message}))
    })
  }
  public postRequest(url: string, data: any = {}): Promise<any> {
    return new Promise((resolve)=>{
      axios({
        method: 'post',
        url: url,
        data: data
      })
      .then((response: AxiosResponse) => resolve({error: null, data: response.data}))
      .catch((reason: any)=>resolve({error: reason.data.message}))
    })
  }
}
