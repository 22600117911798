import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'authentication' },
  { path: 'authentication', loadChildren: () => import('./pages/authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'reports', loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule) },
  { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule) },
  { path: 'billing', loadChildren: () => import('./pages/billing/billing.module').then(m => m.BillingModule) },
  { path: 'communications', loadChildren: () => import('./pages/communications/communications.module').then(m => m.CommunicationsModule) },
  { path: 'messenger', loadChildren: () => import('./pages/messenger/messenger.module').then(m => m.MessengerModule) },
  { path: 'content', loadChildren: () => import('./pages/content/content.module').then(m => m.ContentModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
