<div class="loader" *ngIf="isLoading">
  <div class="spinner-holder">
    <i nz-icon nzType="loading" nzTheme="outline" style="color: white;"></i>
  </div>
</div>

<nz-layout class="app-layout">
  <nz-sider *ngIf="isSidebarVisible" class="menu-sidebar"
            nzCollapsible
            nzWidth="300px"
            nzBreakpoint="md"
            [(nzCollapsed)]="isCollapsed"
            [nzTrigger]="null">
    <div class="sidebar-logo">
      <ng-container *ngIf="accountInfo && accountInfo.platformLogo">
        <img [src]="accountInfo.platformLogo" alt="logo" *ngIf="!isCollapsed">
        <img [src]="accountInfo.platformLogo" alt="logo" *ngIf="isCollapsed">
      </ng-container>
      <ng-container *ngIf="!accountInfo || !accountInfo.platformLogo">
        <img src="../assets/images/lim-icon.png" alt="logo" *ngIf="!isCollapsed">
        <img src="../assets/images/lim-icon.png" alt="logo" *ngIf="isCollapsed">
      </ng-container>
    </div>
    <div class="sidebar-info">
      <table border="0" width="100%" *ngIf="!isCollapsed">
        <tr *ngIf="accountInfo">
          <td style="font-weight: bold;">{{ accountInfo.platformName }}</td>
        </tr>
        <tr *ngIf="orgInfo">
          <td style="font-size: 10px;">{{ orgInfo.orgName }}</td>
        </tr>
        <tr>
          <td style="overflow-x: hidden;">{{ authInfo.getUserInfo().name }}</td>
        </tr>
      </table>
    </div>
    <hr>
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      
      <!-- Sidebar Routes Start -->
      <li *ngFor="let route of routes" nz-menu-item [class.is-active]="route.selected" (click)="routing.navigate(route.path)">
        <i nz-icon [nzType]="route.icon"></i>
        <span>{{route.name}}</span>
      </li>
      <li nz-menu-item>
        Version: {{appVersion}}
      </li>
      <!-- Sidebar Routes End -->

    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header *ngIf="isHeaderVisible">
      <div class="app-header">

        <table border="0" width="100%" class="header-table">
          <tr>
            <td class="left">
              <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
                <i class="trigger"
                   nz-icon
                   [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
                ></i>
              </span>
            </td>
            <td class="right">
              <span class="header-trigger" (click)="authInfo.logout()">
                <i class="trigger"
                   nz-icon
                   nzType="logout"
                ></i>
              </span>
            </td>
          </tr>
        </table>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
