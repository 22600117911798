import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthInfoService } from '../services/authentication/auth-info.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptService implements HttpInterceptor {
  constructor(private authInfo: AuthInfoService){}
   intercept(req: HttpRequest<any>, next: HttpHandler):   Observable<HttpEvent<any>> {
      let newHeaders = req.headers;
      if(this.authInfo.getPlatformID()){ newHeaders = newHeaders.append('x-api-key', this.authInfo.getPlatformID()); }
      if(this.authInfo.isUserAuth()){
        newHeaders = newHeaders.append('authorization', (this.authInfo.getApiToken()) ? this.authInfo.getApiToken() : "");
        const authReq = req.clone({headers: newHeaders});
        return next.handle(authReq);
      }else{
        const sameReq = req.clone({headers: newHeaders});
        return next.handle(sameReq)
      }
   }
}
