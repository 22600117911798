import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthInfoService } from 'src/app/services/authentication/auth-info.service';
import { SidebarHeaderService } from 'src/app/services/sidebar-header.service';
import { RoutingService } from './services/routing.service';
import { OrganizationsService } from './services/organizations.service';
import { LoadingService } from './services/loading.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  
  public isCollapsed: boolean = false;
  public isSidebarVisible: boolean = false;
  public isHeaderVisible: boolean = false;

  public sidebarSub: Subscription;
  public headerSub: Subscription;
  private routeSub: Subscription;
  public accountInfo: any;
  private orgInfo: any;

  public routes: Array<any> = [
    {
      name: "Dashboard",
      icon: "dashboard",
      path: "dashboard",
      selected: false
    },
    {
      name: "Users",
      icon: "user",
      path: "dashboard/users",
      selected: false
    },
    {
      name: "Groups",
      icon: "group",
      path: "dashboard/groups",
      selected: false
    },
    /*{
      name: "Messenger",
      icon: "send",
      path: "messenger",
      selected: false
    },*/
    {
      name: "Communications",
      icon: "mail",
      path: "communications",
      selected: false
    },
    {
      name: "Content",
      icon: "picture",
      path: "content",
      selected: false
    },
    {
      name: "Settings",
      icon: "setting",
      path: "settings",
      selected: false
    }
  ];

  private isLoadingSub: Subscription;
  public isLoading: boolean = false;
  public appVersion: string = environment.appVersion;

  constructor(
    public authInfo: AuthInfoService,
    private sidebarHeader: SidebarHeaderService,
    public routing: RoutingService,
    private orgs: OrganizationsService,
    public loader: LoadingService
  ){}

  ngOnInit(){
    
  }
  ngOnDestroy(){
    this.deconstruct();
  }
  ngAfterViewInit() {
    this.construct();
  }

  private deconstruct(): void {
    if(this.sidebarSub){ this.sidebarSub.unsubscribe() }
    if(this.headerSub){ this.headerSub.unsubscribe() }
    if(this.routeSub){ this.routeSub.unsubscribe() }
    if(this.isLoadingSub){ this.isLoadingSub.unsubscribe() }
  }
  private construct(): void {
    setTimeout(()=>{
      this.isHeaderVisible = this.sidebarHeader.getHeader();
      this.isSidebarVisible = this.sidebarHeader.getSidebar();
    })
    this.deconstruct();
    this.sidebarSub = this.sidebarHeader.subToSidebar().subscribe((status: boolean)=>{
      this.isSidebarVisible = status;
      if(status){
        this.getOrgInfo();
      }
    })
    this.headerSub = this.sidebarHeader.subToHeader().subscribe((status: boolean)=>{
      this.isHeaderVisible = status;
    })
    this.routeSub = this.routing.subToRoute().subscribe((event: any)=>{
      this.routes.forEach((route: any)=>{
        if("/"+route.path == event.url){
          route.selected = true;
        }else{
          route.selected = false;
        }
      })
    })
    this.isLoadingSub = this.loader.getLoadingSub().subscribe((status: boolean)=>{
      this.isLoading = status;
    })
  }

  private getOrgInfo(): void {
    this.orgInfo = this.orgs.getUserOrgInfo();
    this.accountInfo = this.authInfo.getAccountInfo();
  }
}
