import { Injectable, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthInfoService } from './authentication/auth-info.service';
import { DatafeedService } from './datafeed.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  private orgsFeed: Subscription;
  private orgsList: Array<any> = [];
  constructor(private http: HttpService, private datafeed: DatafeedService, private authInfo: AuthInfoService) {

  }

  public getUserOrgInfo(): any {
    this.orgsList = this.datafeed.getData("ORGS");
    let info: any;
    this.orgsList.forEach((row: any)=>{
      if(this.authInfo.getUserOrg() == row.orgID){
        info = row;
      }
    })
    return info;
  }

  public getOrgList(): Array<any> {
    return this.datafeed.getData("ORGS");
  }

  public getUserCount(orgID: string): number {
    let count: number = 0;
    const users: Array<any> = this.datafeed.getData("USERS");
    users.forEach((user: any)=>{
      if(user.role){
        if(user.role.orgID){
          if(user.role.orgID == orgID){
            count += 1;
          }
        }
      }
    })
    return count;
  }

  public searchOrg(name: string){
    return new Promise((resolve)=>{
      this.http.postApi("portal/organizations/search", {name: name.toLowerCase()})
      .then((res: any)=>resolve({exists: (res.data && res.data.exists) ? true : false}))
      .catch((err)=>resolve({exists: true}))
    })
  }

  public deleteOrg(orgID: any): Promise<Array<any>> {
    return new Promise(async (resolve)=>{
      resolve(await this.http.deleteApi("/portal/organizations?orgID="+orgID))
    })
  }

  public searchUser(type: string, value: string){
    return new Promise((resolve)=>{
      this.http.postApi("portal/users/search", {type: type, value: value.toLowerCase()})
      .then((res: any)=>resolve({exists: (res.data && res.data.exists) ? true : false}))
      .catch((err)=>resolve({exists: true}))
    })
  }

  public pushOrg(org: any): Promise<any> {
    return new Promise(async (resolve)=>{
      resolve(await this.http.postApi("portal/organizations",org));
    })
  }

  public getOrgNameById(orgID: string): string {
    const orgList: Array<any> = this.datafeed.getData("ORGS");
    let name: string;
    orgList.forEach((org: any)=>{
      if(org.orgID == orgID){
        name = org.orgName;
      }
    })
    return name;
  }

  public getUserInvites(): Promise<Array<any>> {
    return new Promise(async (resolve)=>{
      const invites: any = await this.http.getApi("/portal/users/invites");
      if(invites.error){ return resolve([]) }
      else{ return resolve(invites.data)}
    })
  }

  public pushUserInvites(value: any): Promise<Array<any>> {
    return new Promise(async (resolve)=>{
      resolve(await this.http.postApi("/portal/users/invites",value))
    })
  }

  public resendUserInvites(inviteID: any): Promise<Array<any>> {
    return new Promise(async (resolve)=>{
      resolve(await this.http.putApi("/portal/users/invites",{type: "resend", value: inviteID}))
    })
  }
  public deleteUserInvites(inviteID: any): Promise<Array<any>> {
    return new Promise(async (resolve)=>{
      resolve(await this.http.deleteApi("/portal/users/invites?inviteID="+inviteID))
    })
  }

  public deleteUser(userID: any): Promise<Array<any>> {
    return new Promise(async (resolve)=>{
      resolve(await this.http.deleteApi("/portal/users?userID="+userID))
    })
  }

  public bulkUsers(form: any): Promise<any> {
    return new Promise(async (resolve)=>{
      resolve(await this.http.postApi("/portal/organizations/bulk-upload",form))
    })
  }

  public updateUser(type: string, value: any, userID: string): Promise<Array<any>> {
    return new Promise(async (resolve)=>{
      resolve(await this.http.putApi("/portal/users",{type: type, value: value, userID: userID}))
    })
  }

  public filterList(q: string, obj: any): boolean {
    let check: boolean = false;
    let searchParam: string = obj["lastName"].trim()+obj["firstName"].trim()+obj["phoneNumber"].trim()+obj["emailAddress"].trim();
    searchParam = searchParam.toLowerCase();
    const params: any = q.split(" ");
    params.forEach((param: string)=>{
      if(searchParam.search(param.trim().toLowerCase()) >= 0){
        check = true;
      }
    })
    return check;
  }

  public uploadFile(name: string, filename: string, mimetype: string, url: string, fileID: string, userAlert: boolean, userID: string){
    return new Promise(async (resolve)=>{
      resolve(await this.http.postApi("portal/files",{name: name, filename: filename, mimetype: mimetype, url: url, fileID: fileID, alert: userAlert, userID: userID}))
    })
  }

  public getFiles(userID: string){
    return new Promise(async (resolve)=>{
      const res: any = await this.http.getApi("portal/files?userID="+userID);
      if(res.error){ return resolve({error: res.error})}
      else{ resolve({error: null, data: res.data})}
    })
  }

  public deleteFiles(fileID: any): Promise<Array<any>> {
    return new Promise(async (resolve)=>{
      resolve(await this.http.deleteApi("/portal/files?fileID="+fileID))
    })
  }
}
