import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class DatafeedService {

  private errorLog: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  private masterData: any = {
    users: {
      isLoaded: false,
      data: new BehaviorSubject<any>([]),
      list: []
    },
    orgs: {
      isLoaded: false,
      data: new BehaviorSubject<any>([]),
      list: []
    },
    comms: {
      isLoaded: false,
      data: new BehaviorSubject<any>([]),
      list: []
    },
    msg: {
      isLoaded: false,
      data: new BehaviorSubject<any>([]),
      list: []
    },
    content: {
      isLoaded: false,
      data: new BehaviorSubject<any>({}),
      list: {}
    },
    subscriptions: {
      isLoaded: false,
      data: new BehaviorSubject<any>([]),
      list: []
    },
    analytics: {
      isLoaded: false,
      data: new BehaviorSubject<any>(null),
      list: null
    },
    app_analytics: {
      isLoaded: false,
      data: new BehaviorSubject<any>(null),
      list: null
    },
    settings: {
      isLoaded: false,
      data: new BehaviorSubject<any>(null),
      list: null
    }
  };

  constructor(
    private http: HttpService
  ) { }

  public getSub(type: string): Observable<any>{
    switch(type){
      case "COMMS":
        return this.masterData.comms.data.asObservable()
      break;
      case "MSGS":
        return this.masterData.msg.data.asObservable()
      break;
      case "USERS":
        return this.masterData.users.data.asObservable()
      break;
      case "ORGS":
        return this.masterData.orgs.data.asObservable()
      break;
      case "CONTENT":
        return this.masterData.content.data.asObservable()
      break;
      case "SUBS":
        return this.masterData.subscriptions.data.asObservable()
      break;
      case "ANLY":
        return this.masterData.analytics.data.asObservable()
      break;
      case "APPANLY":
        return this.masterData.app_analytics.data.asObservable()
      break;
      case "SETS":
        return this.masterData.settings.data.asObservable()
      break;
    }
  }

  public getData(type: string): any{
    switch(type){
      case "COMMS":
        return this.masterData.comms.list;
      break;
      case "MSGS":
        return this.masterData.msg.list;
      break;
      case "USERS":
        return this.masterData.users.list;
      break;
      case "ORGS":
        return this.masterData.orgs.list;
      break;
      case "CONTENT":
        return this.masterData.content.list;
      break;
      case "SUBS":
        return this.masterData.subscriptions.list;
      break;
      case "ANLY":
        return this.masterData.analytics.list;
      break;
      case "SETS":
        return this.masterData.settings.list;
      break;
    }
  }

  public refreshData(type: string): Promise<any> {
    return new Promise(async (resolve)=>{

      switch(type){
        case "COMMS":
          const comms = await this.http.getApi("/portal/communications");
          if(!comms.error){
            this.masterData.comms.data.next(comms.data);
            this.masterData.comms.isLoaded = true;
          }
          return resolve(comms)
        break;
        case "MSGS":
          const messages = await this.http.getApi("/portal/messages");
          if(!messages.error){
            this.masterData.msg.data.next(messages.data);
            this.masterData.msg.isLoaded = true;
          }
          return resolve(messages)
        break;
        case "USERS":
          const users = await this.http.getApi("/portal/users");
          if(!users.error){
            this.masterData.users.data.next(users.data);
            this.masterData.users.isLoaded = true;
          }
          return resolve(users)
        break;
        case "ORGS":
          const orgs = await this.http.getApi("/portal/organizations");
          if(!orgs.error){
            this.masterData.orgs.data.next(orgs.data);
            this.masterData.orgs.isLoaded = true;
          }
          return resolve(orgs)
        break;
        case "CONTENT":
          const content = await this.http.getApi("/portal/content");
          if(!content.error){
            this.masterData.content.data.next(content.data);
            this.masterData.content.isLoaded = true;
          }
          return resolve(content)
        break;
        case "SUBS":
          const subscriptions = await this.http.getApi("/portal/subscriptions");
          if(!subscriptions.error){
            this.masterData.subscriptions.data.next(subscriptions.data);
            this.masterData.subscriptions.isLoaded = true;
          }
          return resolve(subscriptions)
        break;
        case "ANLY":
          const analytics = await this.http.getApi("/portal/analytics");
          if(!analytics.error){
            this.masterData.analytics.data.next(analytics.data);
            this.masterData.analytics.list = analytics.data;
            this.masterData.analytics.isLoaded = true;
          }
          return resolve(analytics)
        break;
        case "SETS":
          const settings = await this.http.getApi("/portal/settings");
          if(!settings.error){
            this.masterData.settings.data.next(settings.data);
            this.masterData.settings.list = settings.data;
            this.masterData.settings.isLoaded = true;
          }
          return resolve(settings)
        break;
      }

    })
  }

  public initData(): Promise<any> {
    return new Promise(async (resolve)=>{
      this.nonCritical()
      const orgs: any = await this.http.getApi("/portal/organizations");
      if(!orgs.error){
        this.masterData.orgs.list = orgs.data;
        this.masterData.orgs.data.next(orgs.data);
        this.masterData.orgs.isLoaded = true;
      }else{
        this.setError(orgs.error)
      }
    
      const users = await this.http.getApi("/portal/users");
      if(!users.error){
        this.masterData.users.list = users.data;
        this.masterData.users.data.next(users.data);
        this.masterData.users.isLoaded = true;
      }else{
        this.setError(users.error)
      }
      
      const content = await this.http.getApi("/portal/content");
      if(!content.error){
        this.masterData.content.list = content.data;
        this.masterData.content.data.next(content.data);
        this.masterData.content.isLoaded = true;
      }else{
        this.setError(content.error)
      }

      const comms = await this.http.getApi("/portal/communications");
      if(!comms.error){
        this.masterData.comms.data.next(comms.data);
        this.masterData.comms.isLoaded = true;
      }else{
        this.setError(comms.error)
      }

      resolve(true)
    })
  }

  public async nonCritical() {
    this._messages();
    this._analytics();
    this._appAnalytics();
    this._settings();
  }

  private async _messages() {
    const messages = await this.http.getApi("/portal/messages");
    if(!messages.error){
      this.masterData.msg.data.next(messages.data);
      this.masterData.msg.isLoaded = true;
    }else{
      this.setError(messages.error)
    }
  }

  private async _analytics() {
    const analytics = await this.http.getApi("/portal/analytics");
    if(!analytics.error){
      this.masterData.analytics.data.next(analytics.data);
      this.masterData.analytics.list = analytics.data;
      this.masterData.analytics.isLoaded = true;
    }else{
      this.setError(analytics.error)
    }
  }

  private async _appAnalytics() {
    const analytics = await this.http.putApi("/portal/analytics");
    if(!analytics.error){
      this.masterData.app_analytics.data.next(analytics.data);
      this.masterData.app_analytics.isLoaded = true;
    }else{
      this.setError(analytics.error)
    }
  }

  private async _settings() {
    await this.refreshData('SETS')
  }

  public errorLogSub(): Observable<Array<any>> {
    return this.errorLog.asObservable();
  }

  public setError(error: any): void {
    this.errorLog.next(error);
  }
}
