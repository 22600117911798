import { AuthInfoService } from '../services/authentication/auth-info.service';
import { RoutingService } from '../services/routing.service';
import { EventloopService } from '../services/eventloop.service';
import { HttpService } from '../services/http.service';

export function AppInit(auth: AuthInfoService, routing: RoutingService, eventloop: EventloopService, http: HttpService) {
  return () => {
    return new Promise (async (resolve)=>{
      let subdomain: string = window.location.hostname.split(".")[0];
      if(subdomain == 'localhost'){
        subdomain = "learim";
      }
      if(subdomain == 'portal'){
        subdomain = "learim";
      }
      const apiKey: any = await http.postApi("/portal/authentication/lookup", {subdomain: subdomain});
      if(!apiKey.error){ 
        auth.setApiKey(apiKey.data) 
        eventloop.startEventLoop();
        routing.navigate("authentication/loading")
      }
      else{
        routing.navigate('authentication/error')
      }
      resolve(await auth.getUserAuthInit())
    })
  }
}
